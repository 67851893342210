import React from "react";
import useTranslationHook from "../../hooks/useTranslationHook";
const ApplyCoupon: React.FC = () => {
  const { t } = useTranslationHook();
  return (
    <div className="promo-code">
      <span className="promo-text">{t("have_promo")}</span>
      <div className="coupon-input-box">
        <input placeholder={t("txt_enter_coupon")} />
        <button>{t("txt_apply_coupon")}</button>
      </div>
    </div>
  );
};

export default ApplyCoupon;
